.ulContainer {
  margin: 1px auto 0;
  padding: 0;
  list-style: none;
  display: table;
  width: 100px;
  text-align: center;
}
.liContainer {
  display: table-cell;
  position: relative;
  padding: 15px 0;
}
.aContainer {
  color: #555;
  text-decoration: none;
  letter-spacing: 0.05em;
  display: inline-block;
  padding: 10px 10px;
  position: relative;
}
.aContainer:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #555;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.aContainer:hover:after {
  width: 100%;
  left: 0;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.paFontStyleHeader {
  font-family: "Open Sans";
  color: #1d567c;
  font-weight: 500;
  font-weight: bold;
}

.gsCenter {
  font-family: "Open Sans";
  font-size: "58";
  color: #1d567c;
  font-weight: 1000;
  display: flex;
  justify-content: "center";
  align-items: "center";
}

.gsFontHeader {
  font-family: "Open Sans";
  font-size: "13";
}

.gsFontTopHeader {
  font-family: "Open Sans";
  font-size: "13";
  color: #fff;
}

.gsParagraph {
  font-family: "Open Sans";
  font-size: "18";
}

.gsSmallFont {
  font-family: "Open Sans";
  font-size: "10";
}

.greenButton {
  width: 140;
  color: white;
  outline: none;
  background-color: #49b755;
  border-color: transparent;
  border-radius: 15px;
}

.rowStyle {
  margin-top: 1mm;
  background-color: #73b0b6;
  border-radius: 0.2cm;
}
.rowStyleSharpe {
  margin-top: 0.5mm;
  background-color: #facfdf;
  border-radius: 0.2cm;
}

.btnStylePos {
  background-color: #49b755;
  border-style: hidden;
  width: 111px;
  height: 60px;
  border-radius: 0%;
  box-shadow: none;
  border: 0;
}

.btnStyleNeg {
  background-color: #cc3322;
  border-style: hidden;
  width: 111px;
  height: 60px;
  border-radius: 0%;
  box-shadow: none;
  border: 0;
}

.chart-container {
  flex: 1;
}

.myContainer {
  width: 700px;
  height: 300px;
  scale: inherit;
}

.stylePos {
  color: #49b755;
  font-weight: bolder;
  border-style: hidden;
  border-radius: 0%;
  box-shadow: none;
  border: 0;
}

.styleNeg {
  color: #cc3322;
  font-weight: bold;
  border-style: hidden;
  border-radius: 0%;
  box-shadow: none;
  border: 0;
}

.btnParagraph {
  font-family: "Segoe UI";
  font-size: 13.65px;
}

.panel-header {
  border: 1px solid #cccc;
  font-weight: normal;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 5px;
  margin-top: 0px;
  padding-top: 0px;
  height: 27px;
}

.feedStyle {
  font-family: "Segoe UI";
  font-size: 14px;
  color: #101629;
}

.newsStyle {
  font-family: "Segoe UI";
  font-size: 14px;
  color: #101629;
  border-left: 1px solid;
  border-color: #aaa;
  padding-left: 4px;
  text-align: left;
}

.newsStyle2 {
  font-family: "Segoe UI";
  font-size: 11px;
  color: #101629;
  padding-left: 4px;
}

.linkStyle {
  font-family: "Arial";
  font-size: 15px;
  color: #101629;
  padding-left: 4px;
}

.redditStyle {
  font-family: "Arial";
  font-weight: bold;
  font-size: 15px;
  color: #101629;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
